export interface URLSegmentList extends ReadonlyArray<keyof URL> {}

/**
 * The state provided to URLStateObserver callbacks.
 */
export interface LocationState {
    /**
     * The previous value of window's location.
     */
    readonly previousUrl: URL;
    /**
     * The current value of window's location.
     */
    readonly url: URL;
}

export type URLStateObserver = (state: LocationState) => void;

interface Subscription {
    notify: URLStateObserver;
    segments: URLSegmentList;
}

const timeout = 300;
/**
 * Queues a function to be called during browser's idle periods.
 * More information: https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback
 */
const requestCallback =
    (
        globalThis as {
            requestIdleCallback?(callback: () => void, options?: { timeout?: number }): void;
        }
    ).requestIdleCallback ??
    function requestIdleCallback(callback: () => void) {
        return setTimeout(callback, timeout);
    };

export class Location {
    /**
     * Location change subscription list
     */
    private static subscriptions = new Set<Subscription>();
    /**
     * The module-level cache of window's location.
     */
    private static prevUrl: URL = Location.getCurrentLocation();

    public static getCurrentLocation(): URL {
        return globalThis.location ? new URL(globalThis.location.href) : new URL('unknown:');
    }

    public static subscribe(
        observer: URLStateObserver,
        { segments = [], initialize = true }: { segments?: URLSegmentList; initialize?: boolean },
    ): () => void {
        const subscription = { notify: observer, segments };
        Location.subscriptions.add(subscription);

        if (initialize) {
            observer({
                previousUrl: Location.prevUrl,
                url: Location.getCurrentLocation(),
            });
        }

        return () => {
            Location.subscriptions.delete(subscription);
        };
    }

    /**
     * The location change checker is based on constantly checking the location.href in a timer.
     *
     * This is the only cross-browser solution since some browsers do not fire popstate event to
     * combat annoying popups. Even if popstate was guaranteed to fire on every history.popState call
     * there are no event fired on pushState() and replaceState().
     *
     * So, polling is the only way.
     * We are using requestIdleCallback in order not to impact UX by overloading the event loop. And,
     * in case that requestIdleCallback is not available (Safari), we default to a regular 300ms timer.
     *
     * When a location change is detected a custom event `locationchange` is dispatched on the document element.
     */
    public static checkLocation(forceDispatch = false) {
        if (!globalThis.location) {
            return;
        }

        if (globalThis.location.href !== Location.prevUrl.href || forceDispatch) {
            const url = Location.getCurrentLocation();
            const state = {
                previousUrl: Location.prevUrl,
                url,
            };

            document.dispatchEvent(new CustomEvent('locationchange', { detail: state }));
            Location.prevUrl = state.url;

            for (const { notify, segments } of Location.subscriptions) {
                if (
                    segments.length === 0 ||
                    segments.some((segment) => state.previousUrl[segment] != state.url[segment])
                ) {
                    notify(state);
                }
            }
        }

        requestCallback(() => Location.checkLocation(), { timeout });
    }
}

// kick off the checker even before any subscriptions are made in order to start firing the
// locationchange event which 3rd-party code (e.g. GTM-injected snippets) may depend on
Location.checkLocation(true);
