import { Location } from './location';

interface Theme {
    readonly id: string;
    readonly title: string;
}

function getAvailableThemes(): ReadonlyArray<Theme> {
    return globalThis.STYLEGUIDE_THEMES || [];
}

const defaultTheme = (() => {
    const themes = getAvailableThemes();
    return (
        themes.find((theme) => theme.id === 'default') ||
        themes[0] || { id: 'default', title: 'Default' }
    );
})();

let activeTheme = (() => {
    const id = new URL(location.href).searchParams.get('theme');
    return getAvailableThemes().find((theme) => theme.id === id) || defaultTheme;
})();

const themeChangedListeners = new Set<(themeId: string | null) => void>();

export function onThemeChanged(listener: (themeId: string | null) => void): () => void {
    themeChangedListeners.add(listener);
    listener(activeTheme.id);
    return () => {
        themeChangedListeners.delete(listener);
    };
}

function setActiveTheme(themeId: string | null): boolean {
    if (!themeId) {
        themeId = defaultTheme.id;
    }

    const newTheme = getAvailableThemes().find((theme) => theme.id === themeId);
    if (!newTheme) {
        return false;
    }

    const url = new URL(location.href);

    if (activeTheme.id !== newTheme.id) {
        if (themeId === defaultTheme.id) {
            url.searchParams.delete('theme');
        } else {
            url.searchParams.set('theme', themeId);
        }

        window.history.pushState(null, '', url.href);

        activeTheme = newTheme;

        for (const listener of themeChangedListeners) {
            listener(themeId);
        }
    }

    return true;
}

Location.subscribe(
    (state) => {
        setActiveTheme(state.url.searchParams.get('theme'));
    },
    { segments: ['search'] },
);

class ThemeSwitcher extends HTMLElement {
    #unsubscribe: () => void;
    #select: HTMLSelectElement;
    constructor() {
        super();
    }
    connectedCallback() {
        this.#select = document.createElement('select');
        this.appendChild(this.#select);

        for (const theme of getAvailableThemes()) {
            this.#select.options.add(
                new Option(
                    theme.title,
                    theme.id,
                    theme.id === defaultTheme.id,
                    theme.id === activeTheme?.id,
                ),
            );
        }
        this.#select.addEventListener('change', (_) => {
            setActiveTheme(this.#select.value);
        });
        this.#unsubscribe = onThemeChanged((themeId) => {
            for (const option of this.#select.options) {
                option.selected = option.value === themeId;
            }
        });
    }
    disconnectedCallback() {
        this.#unsubscribe();
    }
}

customElements.define('theme-switcher', ThemeSwitcher);

document.addEventListener('DOMContentLoaded', () => {
    const stories = document.querySelectorAll('.story');
    onThemeChanged(() => {
        const theme = activeTheme.id;
        for (const story of stories) {
            const iframe = story.querySelector<HTMLIFrameElement>('iframe')!;
            const url = new URL(iframe.src);
            if (theme === defaultTheme.id) {
                url.searchParams.delete('theme');
            } else {
                url.searchParams.set('theme', theme);
            }
            iframe.src = url.href;
            for (const link of story.querySelectorAll<HTMLAnchorElement>('.story-link')) {
                link.href = url.href;
            }
        }
    });
});
